
  import DossierInfoChart from '~/components/dashboard/DossierInfoChart.vue'
  import DossierInProgressChart from '~/components/dashboard/DossierInProgressChart.vue'
  import ScreeningTimeChart from '~/components/dashboard/ScreeningTimeChart.vue'
  import ResultsChart from '~/components/dashboard/ResultsChart.vue'

  export default {
    components: { DossierInfoChart, DossierInProgressChart, ScreeningTimeChart, ResultsChart },
    data() {
      return {}
    }
  }
