
  export default {
    name: `DossierInfoChart`,
    props: {},
    data() {
      return {
        inProgressValue: 0,
        toBeDeletedValue: 0,
        finalisedOrCancelledValue: 0,
        waitingForCandidateValue: 0
      }
    },

    async fetch() {
      try {
        const response = await this.$api.dashboard.getChatsDossierInfo()
        this.inProgressValue = response.in_progress_value
        this.toBeDeletedValue = response.to_be_deleted_value
        this.finalisedOrCancelledValue = response.finalised_or_cancelled_value
        this.waitingForCandidateValue = response.waiting_for_candidate_value
      } catch (error) {
        console.error(`Error fetching chart data:`, error)
      }
    }
  }
