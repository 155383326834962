
  export default {
    name: `ScreeningTimeChart`,
    props: {},
    data() {
      return {
        chartValues: [],
        chartPercentages: [],
        hasData: false,
        chartSeries: [
          {
            name: this.$t(`components.dashboard.screening_time.number_of_dossiers`),
            type: `bar`,
            data: []
          },
          { name: ``, type: `scatter`, data: [] },
          { name: ``, type: `scatter`, data: [] }
        ],
        chartOptions: {
          chart: {
            stacked: false,
            height: `350px`,
            toolbar: {
              show: false
            }
          },
          grid: {
            show: false
          },
          legend: {
            show: false
          },
          yaxis: {
            labels: {
              show: false
            },
            title: { text: `` }
          },
          title: { text: ``, align: `left` },
          xaxis: {
            categories: [],

            labels: {
              style: { fontSize: `15px` }
            }
          },
          tooltip: {
            enabledOnSeries: [0],
            y: {
              formatter: function (value) {
                return parseFloat(value).toFixed(0)
              }
            }
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: `top`
              }
            }
          },
          colors: [`rgba(160, 218, 248, 1)`, `transparent`],
          dataLabels: {
            enabledOnSeries: [1, 2],
            enabled: true,

            style: { fontSize: `15px`, colors: [`#000000`] },
            offsetY: 0,
            formatter: (val, opts) => {
              if (val === 0) return ``
              if (opts.seriesIndex === 1) {
                return this.chartValues[opts.dataPointIndex]
              } else {
                return this.chartPercentages[opts.dataPointIndex] + `%`
              }
            }
          }
        }
      }
    },

    async fetch() {
      try {
        const response = await this.$api.dashboard.getChatsScreeningTime()
        const total = response.chartValues.reduce((a, b) => a + b, 0)
        if (total > 0) {
          this.hasData = true;
        }
        this.chartSeries[0].data = response.chartValues
        this.chartSeries[1].data = response.chartValues.map((value, index) => {
          return value + total / 40
        })
        this.chartSeries[2].data = response.chartValues.map((value, index) => {
          return value / 2
        })
        this.chartValues = response.chartValues
        this.chartPercentages = response.chartPercentages

        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { categories: response.categories }
        }
      } catch (error) {
        console.error(`Error fetching chart data:`, error)
      }
    }
  }
